import React, { createContext, useEffect, useState } from "react";
import prepareStripe from "../helpers/prepareStripe";
import { currentUser, User } from "../helpers/supabase";
import { IStripeProduct, IStripeSubscription } from "../types";

interface IAuthContext {
  user: User | null;
  isAuthenticated: boolean;
  loading: boolean;
  subscription: IStripeSubscription | null;
  products: IStripeProduct[];
  getUser: () => void;
  refetch: () => void;
}

export const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  user: null,
  loading: true,
  subscription: null,
  products: [],
  getUser: () => {},
  refetch: () => {},
});

type AuthStateType = Omit<IAuthContext, "getUser" | "refetch">;

const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthStateType>({
    user: null,
    products: [],
    loading: true,
    isAuthenticated: false,
    subscription: null,
  });

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    const setStripeState = (subscription: IStripeSubscription | null, products: IStripeProduct[]) => {
      setAuthState((state) => ({
        ...state,
        loading: false,
        subscription,
        products
      }));
    };

    setAuthState((state) => ({ ...state, loading: true }));
    prepareStripe(authState.user?.email ?? null, setStripeState);
  }, [authState.user]);

  const refetch = async () => {
    const user = await currentUser();
    setAuthState((state) => ({ ...state, user }));
  };

  const getUser = async () => {
    setAuthState((state) => ({ ...state, loading: true }));

    const user = await currentUser();

    if (user) {
      setAuthState((state) => ({
        ...state,
        isAuthenticated: true,
        user,
        loading: false,
      }));
      return;
    }

    setAuthState((state) => ({
      ...state,
      isAuthenticated: false,
      user: null,
      loading: false,
    }));
  };

  return (
    <AuthContext.Provider value={{ ...authState, getUser, refetch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
