import React from "react";
import AuthContextProvider from "./src/contexts/AuthContext";
import "./src/styles/global.scss";
import { MixpanelContext } from './src/tracking';


export const wrapRootElement = ({ element }) => {

  return (
    <section data-app-name="ensembleanalytics">
        <AuthContextProvider>{element}</AuthContextProvider>
    </section>
  );
};
