import React from "react";

interface IInformationEmbedProps {
  title: string;
  url: string;
}

const InformationEmbed: React.FC<IInformationEmbedProps> = ({ url, title }) => {
  return (
    <a className="block !no-underline" href={url} target="_blank" rel="noreferrer noopener">
      <div className="flex items-center justify-between px-4 link-collapsed">
        <div className="font-[450] text-base text-black">
          <div className="flex items-center">
            <div className="w-[30px] h-[30px] flex justify-center items-center bg-white rounded-full text-[#5498c0]">
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.6"
                  d="M15.7161 16.2236H8.49609"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  opacity="0.6"
                  d="M15.7161 12.0371H8.49609"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  opacity="0.6"
                  d="M11.2511 7.86035H8.49609"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.908 2.75C15.908 2.75 8.231 2.754 8.219 2.754C5.459 2.771 3.75 4.587 3.75 7.357V16.553C3.75 19.337 5.472 21.16 8.256 21.16C8.256 21.16 15.932 21.157 15.945 21.157C18.705 21.14 20.415 19.323 20.415 16.553V7.357C20.415 4.573 18.692 2.75 15.908 2.75Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="ml-4">
              <span className="!text-white">
                <strong className="!text-white">{title}</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default InformationEmbed;
