import { IStripeProduct, IStripeSubscription } from "../types";

async function prepareStripe(
  email: string | null,
  setStripeState: (
    subscription: IStripeSubscription | null,
    products: IStripeProduct[]
  ) => void
) {
  let subscription: IStripeSubscription | null = null;
  let products: IStripeProduct[] = [];

  try {
    const productsRes = await fetch("/api/stripe-products");

    products =
      (await productsRes.json())?.filter(
        (product: IStripeProduct) => product.active
      ) ?? [];

    if (!email) {
      setStripeState(null, products);
      return;
    }

    const customerResp = await fetch(`/api/customers-search?email=${email}`);
    const customer = await customerResp.json();

    if (!customer?.id) {
      setStripeState(null, products);
      return;
    }

    const subscriptionResp = await fetch(
      `/api/subscriptions-search?customer_id=${customer.id}`
    );
    subscription =
      (await subscriptionResp.json())?.find(
        (sub: IStripeSubscription) => sub.status === "active"
      ) ?? null;

    setStripeState(subscription, products);
  } catch (err) {
    console.error(err);
    setStripeState(subscription ?? null, products ?? null);
  }
}

export default prepareStripe;
