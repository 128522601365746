import React from "react";
import "./Faq.scss";

interface IFaqProps {
  title: JSX.Element;
  description?: JSX.Element;
  noPadding?: boolean,
  questions: Array<{
    title: string;
    text: JSX.Element;
  }>;
}

const Faq: React.FC<IFaqProps> = ({ title, description, questions, noPadding }) => {
  return (
    <section className={`max-w-[900px] mx-auto ${noPadding ? 'my-0' : 'w-[90%] my-20 py-10'}`}>
      <div className="text-center">
        {title && (
          <h2 className="text-[22px] lg:text-[22px] font-bold text-center pb-6">
            {title}
          </h2>
        )}
        {description && <p>{description}</p>}
      </div>
      <div className="faq">
        {questions.map((loop, i) => (
          <details key={i}>
            <summary>{loop.title}</summary>
            <div>{loop.text}</div>
          </details>
        ))}
      </div>
    </section>
  );
};

export default Faq;
