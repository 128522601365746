import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Html from "../../../../src/components/BlogPostHtml";
import InformationEmbed from "../../../../src/components/mdx/InformationEmbed";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  Html,
  InformationEmbed,
  React
};