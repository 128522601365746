import React from "react";


const Button = ({ children }: {
    children: React.ReactNode,
}) => {

    return (
        <>
            {children}
        </>
    );
};

export default Button;
